import { Controller, useFormContext } from "react-hook-form";
import { useDropzone } from "react-dropzone";
import { Fragment, useEffect, useMemo, useState } from "react";
import { FaUpload } from "react-icons/fa";
import { Button } from "../Button";
import { toast } from "react-toastify";
import { Title } from "../Title";
import { useTranslation } from "react-i18next";

type Props = {
    container: any,
    setContainer: any,
    inputName: string,
    label?: string
};

const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "50px 20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#F1F5F6",
    borderStyle: "dashed",
    backgroundColor: "#FAFAFA",
    color: "#A7A7A7",
    outline: "none",
    transition: "border .24s ease-in-out"
};

const focusedStyle = {
    borderColor: "#F1F5F6"
};

const acceptStyle = {
    borderColor: "#00b7ca"
};

const rejectStyle = {
    borderColor: "#ff1744"
};

// 50 MB 
const MAX_SIZE_FILE = 52428800;

export const InputFileDragDrop = ({ label, inputName, container, setContainer }: Props) => {
    // hooks
    const { t } = useTranslation();
    const { control, setValue, watch } = useFormContext();

    const formatFileSize = (size: number) => {
        const units = ["B", " KB", " MB", " GB", " TB", " PB", " EB"];
        let unitIndex = 0;
        let formattedSize = size;

        while (formattedSize >= 1000) {
            formattedSize /= 1000;
            unitIndex++;
        }

        return `${formattedSize.toFixed(2)}${units[unitIndex]}`;
    };

    const removeFile = (file: any) => () => {
        const newFiles = [...container];
        newFiles.splice(newFiles.indexOf(file), 1);
        setContainer(newFiles);
    };

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isFocused,
        isDragAccept,
        isDragReject,
    } = useDropzone({
        onDrop(acceptedFiles) {
            setContainer([...container, ...acceptedFiles]);
        },
        onDropRejected(fileRejections) {
            fileRejections.forEach((fileRejection) => {
                fileRejection.errors.forEach((e) => {
                    toast.error(`${e.message.replace(MAX_SIZE_FILE + " bytes", formatFileSize(MAX_SIZE_FILE))} (${fileRejection.file.name} - ${formatFileSize(fileRejection.file.size)})`);
                });
            });
        },
        maxSize: MAX_SIZE_FILE,
    });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    useEffect(() => {
        setValue(inputName, container);
    }, [container]);

    return (
        <section className="mb-8">
            {label && <Title variant={Title.variant.secondary} className="pb-4">{t(label)}</Title>}
            <Controller
                control={control}
                name={inputName}
                render={({ field: { value, onChange, ...field } }) => {
                    return (
                        <div {...getRootProps({ className: "dropzone", style: style as any })}>
                            <input
                                id={inputName}
                                {...getInputProps()}
                                {...field}
                            />
                            <span className="text-xl flex gap-3">
                                <FaUpload />
                                {isDragActive ? (
                                    <div className="text-base">Drop the files here ...</div>
                                ) : (
                                    <div className="text-base">{t("Trascina e rilascia qui i file o fai clic per selezionarli")}</div>
                                )}
                            </span>
                        </div>
                    );
                }}
            />

            {container?.length > 0 && <div className="px-6 py-4 border-x border-b text-sm">
                <ul className="font-light space-y-2">
                    {
                        container.map((file: any, index: any) => (
                            <Fragment key={index}>
                                <li className="flex justify-between items-center gap-4">
                                    <span>{file.name} - {formatFileSize(file.size)}</span>
                                    <Button icon={Button.icon.delete} variant={Button.variant.text} type="button" onClick={removeFile(file)}></Button>
                                </li>
                                <div className="border-b last:hidden"></div>
                            </Fragment>
                        ))
                    }
                </ul>
            </div>}
        </section>
    );
};
