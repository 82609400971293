import { useNavigate, useParams } from "react-router-dom";
import { Loader } from "../components/Loader";
import useSemiramisComplaint from "../api/semiramis-complaints/useSemiramisComplaint";
import { InputFileDragDrop } from "../components/commom/InputFileDragDrop";
import { FormProvider, useForm } from "react-hook-form";
import { Fragment, useEffect, useState } from "react";
import { Status, StatusVariant } from "../components/Status";
import { useTranslation } from "react-i18next";
import { Title } from "../components/Title";
import { Button } from "../components/Button";
import client from "../api/client";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import { useAuthContext } from "../hooks/use-context/useAuthContext";
import { downloadResource } from "../constants/DownloadResource";
import { Modal } from "../components/commom/Modal";

function ComplaintDetails({ setTitle }: any) {
    const { t } = useTranslation();
    const { id } = useParams();
    const methods = useForm();
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const { roles, can } = useAuthContext();

    // state
    const [state, setState] = useState<keyof typeof StatusVariant>("order_processing");
    const [description, setDescription] = useState<string>();
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [openDeletePublicFile, setOpenDeletePublicFile] = useState<number | null>(null);
    const [openDeletePrivateFile, setOpenDeletePrivateFile] = useState<number | null>(null);
    const [publicFiles, setPublicFiles] = useState([]);
    const [privateFiles, setPrivateFiles] = useState([]);

    // react query
    const { complaint, isLoading, isFetching } = useSemiramisComplaint(id);

    // DOWNLOAD FILE
    const handleFileDownload = async (media_id: string) => {
        const res = await client.get(`/complaints/${complaint?.data?.eservice_complaint?.id}/download/${media_id}`);

        if (res.data && res.data.content && res.data.name && res.data.type) {
            const filetype = res.data.type;
            const content = res.data.content;
            const filename = res.data.name;

            downloadResource(content, filename, filetype);
        }
    };

    // DELETE FILE
    const handleDeleteFile = async (media_id: string) => {
        await client.delete(`complaints/${complaint?.data?.eservice_complaint?.id}/files/${media_id}/delete`);
        queryClient.invalidateQueries(["semiramis_complaint"]);
    };

    // SUBMIT FILES
    const handleSubmit = async (data: any) => {
        const formData = new FormData();

        // append data
        formData.append("data", JSON.stringify(data));

        // append public and private files separatelly
        if (data.public_files) {
            for (let i = 0; i < data.public_files.length; i++) {
                formData.append(`public_files-${i}`, data.public_files[i]);
            }
        }

        if (data.private_files) {
            for (let i = 0; i < data.private_files.length; i++) {
                formData.append(`private_files-${i}`, data.private_files[i]);
            }
        }

        setIsUploading(true);

        try {
            await client.post(`complaints/${id}`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            });

            setIsUploading(false);
            methods.reset();
            setPrivateFiles([]);
            setPublicFiles([]);
            queryClient.invalidateQueries(["semiramis_complaint"]);

            toast.success(t("Le tue modifiche sono state salvate con successo!"));
        } catch (err: any) {
            console.log(err);
            setIsUploading(false);
            toast.error(t("Qualcosa è andato storto"));
        }
    };

    useEffect(() => {
        if (complaint?.data?.number) {
            setTitle(t("Reclamo numero {{num}}", { num: complaint.data.number }));
        }

        switch (complaint?.data?.status) {
            case "1":
                setState("order_processing");
                setDescription(t("In elaborazione"));
                break;

            case "2":
                setState("order_concluded");
                setDescription(t("Creato"));
                break;

            case "3":
                setState("order_closed");
                setDescription(t("Chiuso"));
                break;
        }
    }, [complaint]);

    return <>
        {(isLoading || isFetching || isUploading) && <Loader />}

        <div className="py-10 px-8 2xl:px-28 flex flex-col">

            {/* COMPLAINT INFO */}
            <div className="p-6 mb-8 border text-sm">
                <div className="font-light space-y-2">
                    <Title variant={Title.variant.secondary}>{t("Dati reclamo")}</Title>

                    <div className="space-y-2">
                        <div className="flex space-x-1">
                            <span>
                                {t("Stato")}:
                            </span>
                            <span className="font-normal break-words">
                                <Status variant={Status.variant[state]}>{description}</Status>
                            </span>
                        </div>

                        <div className="space-x-1">
                            <span>
                                {t("Numero reclamo")}:
                            </span>
                            <span className="font-normal break-words">
                                {complaint?.data?.number}
                            </span>
                        </div>

                        <div className="space-x-1">
                            <span>
                                {t("Tipo reclamo")}:
                            </span>
                            <span className="font-normal break-words">
                                {complaint?.data?.complaint_type.description}
                            </span>
                        </div>

                        <div className="space-x-1">
                            <span>
                                {t("Tipo di errore")}:
                            </span>
                            <span className="font-normal break-words">
                                {complaint?.data?.complaint_classification_one?.description}
                            </span>
                        </div>

                        <div className="space-x-1">
                            <span>
                                {t("Data")}:
                            </span>
                            <span className="font-normal break-words">
                                {complaint?.data?.date}
                            </span>
                        </div>

                        <div className="space-x-1">
                            <span>
                                {t("Codice articolo")}:
                            </span>
                            <span className="font-normal break-words">
                                {complaint?.data?.item_code || complaint?.data?.item_id || "-"}
                            </span>
                        </div>

                        <div className="space-x-1">
                            <span>
                                {t("Numero Bolla")}:
                            </span>
                            <span className="font-normal break-words">
                                {complaint?.data?.eservice_complaint?.delivery_order || "-"}
                            </span>
                        </div>

                        <div className="space-x-1">
                            <span>
                                {t("Classificazione articolo")}:
                            </span>
                            <span className="font-normal break-words">
                                {complaint?.data?.item_priceclassification || "-"}
                            </span>
                        </div>

                        <div className="space-x-1">
                            <span>
                                {t("Descrizione")}:
                            </span>
                            <span className="font-normal break-words">
                                {complaint?.data?.eservice_complaint?.description ?? "-"}
                            </span>
                        </div>

                        {complaint?.data?.complaint_classification_five?.description && <div className="space-x-1">
                            <span>
                                {t("Soluzione")}:
                            </span>
                            <span className="font-normal">
                                {complaint?.data?.complaint_classification_five?.description}
                            </span>
                        </div>}
                    </div>
                </div>
            </div>

            {/* CUSTOMER INFO */}
            <div className="p-6 mb-8 border text-base bg-neutral-weaker">
                <div className="font-light space-y-2">
                    <h2 className="text-lg font-sans text-fg-lighter font-normal">{t("Cliente")}</h2>
                    <div className="uppercase">
                        {complaint?.data?.customer?.name}
                    </div>

                    {
                        complaint?.data?.customer?.code &&
                        <div className="space-x-1 uppercase">
                            <span>
                                {t("Codice cliente")}:
                            </span>
                            <span>
                                {`${complaint?.data?.customer?.code}`}
                            </span>
                        </div>
                    }

                    {
                        complaint?.data?.customer?.ts_code && complaint?.data?.customer?.ts_fullname &&
                        <div className="space-x-1 uppercase">
                            <span>{t("Technical Salesman")}:</span>
                            <span>
                                {`${complaint?.data?.customer?.ts_code} - ${complaint?.data?.customer?.ts_fullname}`}
                            </span>
                        </div>
                    }
                </div>
            </div>


            {
                complaint?.data?.eservice_complaint?.details && !roles(["CLIENT", "SUB_CLIENT", "GROUP_LEADER"]) &&
                <div className="w-full p-6 mb-8 border">
                    <p className="font-light whitespace-pre-wrap">
                        {complaint?.data?.eservice_complaint?.details}
                    </p>
                </div>
            }

            {/* PUBLIC FILES */}
            {
                Array.isArray(complaint?.data?.eservice_complaint?.media) && complaint?.data?.eservice_complaint?.media?.filter((media: any) => media.custom_properties.rothoVisibility === "public").length > 0 &&
                <div className="p-6 mb-8 border text-sm">
                    <div className="font-light space-y-2">
                        <Title variant={Title.variant.secondary}>{can(["can_see_private_complaint_files"]) ? t("Documenti pubblici") : t("Documenti")}</Title>

                        <div className="space-y-6 md:space-y-2 mt-6">
                            {
                                complaint?.data?.eservice_complaint?.media?.filter((media: any) => media.custom_properties.rothoVisibility === "public").map((el: any, index: number) => {
                                    const data = new Date(el.created_at);
                                    const formattedData = data.toLocaleDateString();

                                    return <Fragment key={index}>
                                        <div className="flex flex-col md:flex-row md:justify-between md:items-center gap-2">
                                            <span>
                                                {el.file_name}
                                            </span>
                                            <div className="flex max-md:flex-col gap-6 md:items-center">
                                                <span>
                                                    {formattedData}
                                                </span>
                                                <Button
                                                    onClick={() => handleFileDownload(el.id)}
                                                    icon={Button.icon.download}
                                                    variant={Button.variant.secondary}
                                                    className="w-full md:w-auto"
                                                >
                                                    {t("Download")}
                                                </Button>
                                                <Button icon={Button.icon.delete} variant={Button.variant.text} type="button" onClick={() => setOpenDeletePublicFile(el.id)}></Button>
                                            </div>
                                        </div>
                                        <div className="border-b last:hidden"></div>

                                        {/* MODAL */}
                                        <Modal title={t("Elimina file")} open={openDeletePublicFile === el.id} onClose={setOpenDeletePublicFile}>
                                            <div className="space-y-2">
                                                <div>{t("Sei sicuro di voler eliminare questo file?")}</div>

                                                <div className="text-right space-x-4 mt-4">
                                                    <Button type="button" variant={Button.variant.text} onClick={() => setOpenDeletePublicFile(null)}>{t("Annulla")}</Button>
                                                    <Button variant={Button.variant.primary} onClick={() => handleDeleteFile(el.id)}>{t("Elimina")}</Button>
                                                </div>
                                            </div>
                                        </Modal>
                                    </Fragment>;
                                })
                            }
                        </div>
                    </div>
                </div>
            }

            {/* PRIVATE FILES */}
            {
                Array.isArray(complaint?.data?.eservice_complaint?.media) && complaint?.data?.eservice_complaint?.media?.filter((media: any) => media.custom_properties.rothoVisibility === "private").length > 0 && can(["can_see_private_complaint_files"]) &&
                <div className="p-6 mb-8 border text-sm">
                    <div className="font-light space-y-2">
                        <Title variant={Title.variant.secondary}>{t("Documenti privati")}</Title>

                        <div className="space-y-6 md:space-y-2 mt-6">
                            {
                                complaint?.data?.eservice_complaint?.media?.filter((media: any) => media.custom_properties.rothoVisibility === "private").map((el: any, index: number) => {
                                    const data = new Date(el.created_at);
                                    const formattedData = data.toLocaleDateString();

                                    return <Fragment key={index}>
                                        <div className="flex flex-col md:flex-row md:justify-between md:items-center gap-2">
                                            <span>
                                                {el.file_name}
                                            </span>
                                            <div className="flex max-md:flex-col gap-6 md:items-center">
                                                <span>
                                                    {formattedData}
                                                </span>
                                                <Button
                                                    onClick={() => handleFileDownload(el.id)}
                                                    icon={Button.icon.download}
                                                    variant={Button.variant.secondary}
                                                    className="w-full md:w-auto"
                                                >
                                                    {t("Download")}
                                                </Button>
                                                <Button icon={Button.icon.delete} variant={Button.variant.text} type="button" onClick={() => setOpenDeletePrivateFile(el.id)}></Button>
                                            </div>
                                        </div>
                                        <div className="border-b last:hidden"></div>

                                        {/* MODAL */}
                                        <Modal title={t("Elimina file")} open={openDeletePrivateFile === el.id} onClose={setOpenDeletePrivateFile}>
                                            <div className="space-y-2">
                                                <div>{t("Sei sicuro di voler eliminare questo file?")}</div>

                                                <div className="text-right space-x-4 mt-4">
                                                    <Button type="button" variant={Button.variant.text} onClick={() => setOpenDeletePrivateFile(null)}>{t("Annulla")}</Button>
                                                    <Button variant={Button.variant.primary} onClick={() => handleDeleteFile(el.id)}>{t("Elimina")}</Button>
                                                </div>
                                            </div>
                                        </Modal>
                                    </Fragment>;
                                })
                            }
                        </div>
                    </div>
                </div>
            }

            {/* UPLOAD FILES */}
            {
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(handleSubmit)}>
                        <div className="space-y-6">
                            {/* PUBLIC FILES */}
                            <InputFileDragDrop label={can(["can_see_private_complaint_files"]) ? "File pubblici" : ""} inputName="public_files" container={publicFiles} setContainer={setPublicFiles} />

                            {/* PRIVATE FILES */}
                            {
                                can(["can_see_private_complaint_files"]) &&
                                <InputFileDragDrop label="File privati" inputName="private_files" container={privateFiles} setContainer={setPrivateFiles} />
                            }
                            <div className="flex items-center justify-between gap-6">
                                <Button
                                    variant={Button.variant.text}
                                    icon={Button.icon.letfArrow}
                                    onClick={() => navigate(-1)}
                                    type="button"
                                >
                                    {t("Indietro")}
                                </Button>

                                <Button
                                    variant={Button.variant.primary}
                                    icon={Button.icon.upload}
                                    isDisabled={methods.watch("public_files")?.length <= 0 && methods.watch("private_files")?.length <= 0}
                                >
                                    {t("Carica documenti")}
                                </Button>
                            </div>
                        </div>
                    </form>
                </FormProvider>
            }
        </div>
    </>;

}

export default ComplaintDetails;
