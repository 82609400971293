import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { BiChevronDownCircle } from "react-icons/bi";
import { AiFillPhone } from "react-icons/ai";
import { GrMail } from "react-icons/gr";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { Button } from "../components/Button";
import { useAuthContext } from "../hooks/use-context/useAuthContext";
import { Link, useNavigate } from "react-router-dom";
import classNames from "classnames";
import { MdClose, MdMenu } from "react-icons/md";
import { Card } from "../components/Card";
import { Impersonate } from "../components/commom/Impersonate";
import Select from "react-select";
import { useSelectStyles } from "../hooks/useSelectStyles";
import { AvailableLanguages } from "../constants/AvailableLanguages";
import client from "../api/client";
import { useQueryClient } from "@tanstack/react-query";
import { MdKeyboardArrowRight } from "react-icons/md";

const Header = () => {
    // hooks
    const { t, i18n } = useTranslation();
    const { CustomStyles } = useSelectStyles();
    const { user, roles, can, setUser } = useAuthContext();
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    // states
    const [openNav, setOpenNav] = useState<boolean>(false);
    const [isOpen, setIsOpen] = useState<boolean>(true);
    const [isMobile, setIsMobile] = useState<boolean>(false);

    const tsImageName = user?.customers?.[0]?.technicalSale?.email?.split("@")[0]?.toLowerCase().replaceAll("xx$", "");
    const csImageName = user?.customers?.[0]?.customerService?.email?.split("@")[0]?.toLowerCase().replaceAll("xx$", "");

    const changeLanguage = async (value: any) => {
        try {
            const res = await client.post("/language/self/interface", { language_id: value.value });
            if (res.data.success) {
                i18n.changeLanguage(value.value);
                if (user) {
                    user.interface_language_id = value.value;
                    setUser({ ...user });
                }
            }
        } catch (err) {
            console.log(err);
        }
    };

    const logout = async () => {
        await client.post("logout");
        // clear states and session storage
        setUser(null);
        sessionStorage.clear();
        queryClient.removeQueries();
        navigate("/");
    };

    useEffect(() => {
        setOpenNav(false);
    }, [navigate]);

    useEffect(() => {
        setIsMobile("ontouchstart" in window || navigator.maxTouchPoints > 0);
    }, []);

    const isProduction: boolean = ["production"].indexOf(process.env.REACT_APP_ENV || "") >= 0;

    return (
        <div className="z-50 sticky top-0">
            {/* HEADER */}
            <div className={classNames("bg-primary w-full flex gap-4 px-4", {
                "h-14 justify-between": user,
                "h-32 md:h-20 flex-col md:flex-row md:items-center justify-center md:justify-between": !user,
            })}>
                <div className="flex flex-wrap items-center gap-x-4">
                    <div className={"h-7"}>
                        <Link to="/dashboard">
                            <img src="/rothoblaas.png" alt="" className="h-full" />
                        </Link>
                    </div>
                    <div className={"text-white md:text-xl text-sm leading-6 mt-4 md:mt-3"}>
                        {(process.env.REACT_APP_ENV || "").includes("production") ? (
                            <Link to={"/"}>E-service</Link>
                        ) : (
                            <Link to={"/"}>
                                <span className='animate-pulse text-orange-500'>E-service {t("test environment")}</span>
                            </Link>
                        )}
                    </div>
                </div>
                {user ? (
                    <>
                        <div
                            className="text-white border-l border-gray-600 pl-4 h-full flex gap-2 items-center"
                            onMouseEnter={() => !isMobile && setOpenNav(true)}
                            onMouseLeave={() => !isMobile && setOpenNav(false)}
                        >
                            <div className="flex gap-2 items-center cursor-default" onClick={() => {
                                if (isMobile) {
                                    setOpenNav(!openNav);
                                }
                            }}>
                                <span className="text-sm uppercase ">{t("Ciao") + ", " + user.name}</span>
                                <span className="text-lg">{!openNav ? <MdMenu /> : <MdClose />}</span>
                            </div>
                            {openNav && <div className={`z-50 absolute ${!isMobile ? "top-14 md:top-0 md:pt-14" : "top-14"} inset-x-0 md:left-auto md:right-5 text-black cursor-auto md:min-w-[730px] w-full md:w-1/2`}>
                                <div className={`hidden md:block absolute ${!isMobile ? "top-14" : "top-0"} right-9 w-0 h-0 border-l-[10px] border-transparent border-b-[20px] border-b-white border-r-[10px]`}></div>
                                <div className="w-full h-full rounded-sm bg-transparent ">
                                    <div className="relative top-0 md:top-5 md:shadow-md h-full w-full bg-white max-h-[calc(100vh-56px)] md:max-h-[calc(100vh-96px)] overflow-y-auto">
                                        <div className="p-10 h-full space-y-6">

                                            {/* HIDE IN CASE ESERVICE DOWN */}
                                            {
                                                ((process.env.REACT_APP_DOWN_FOR_CLIENTS === "false" || !process.env.REACT_APP_DOWN_FOR_CLIENTS) ||
                                                    (process.env.REACT_APP_DOWN_FOR_CLIENTS === "true" && !roles(["CLIENT", "SUB_CLIENT", "GROUP_LEADER"]))) &&
                                                <>
                                                    <div className="uppercase text-sm font-semibold text-fg-lighter">{t("Il tuo account")}</div>
                                                    <div className="space-y-6">
                                                        <div className="grid md:grid-cols-2 gap-8">
                                                            {isMobile && <Card variants={Card.variants.header} icon={Card.icon.home} title={t("Dashboard")} url='/dashboard' />}
                                                            <Card variants={Card.variants.header} icon={Card.icon.cart} title={roles(["CLIENT", "GROUP_LEADER", "SUB_CLIENT"]) ? t("Nuovo ordine") : t("Nuovo ordine o offerta")} url='/carts' />
                                                            <Card variants={Card.variants.header} icon={Card.icon.boxCheck} title={t("I miei carrelli")} url='/lists' />
                                                            <Card variants={Card.variants.header} icon={Card.icon.hands} title={t("Le mie offerte")} url='/offers' />
                                                            <Card variants={Card.variants.header} icon={Card.icon.cartDownload} title={t("I miei ordini")} url='/orders' />
                                                            <Card variants={Card.variants.header} icon={Card.icon.truck} title={t("Le mie spedizioni")} url='/shippings' />
                                                            {!isProduction && <Card variants={Card.variants.header} icon={Card.icon.profile} title={t("Anagrafica")} url='/profile' />}
                                                            <Card variants={Card.variants.header} icon={Card.icon.note} title={t("Le mie fatture")} url='/invoices' />
                                                            <Card variants={Card.variants.header} icon={Card.icon.info} title={t("Assistenza")} url='/assistance' />
                                                            <Card variants={Card.variants.header} icon={Card.icon.lock} title={t("Condizioni riservate")} url='/reserved-conditions' />
                                                            {
                                                                can(["can_manage_employees", "can_manage_clients"]) &&
                                                                <Card variants={Card.variants.header} icon={Card.icon.users} title={t("Gestione utenti")} url="/users-management" />
                                                            }
                                                            {
                                                                can(["can_see_margin_calculator"]) &&
                                                                <Card variants={Card.variants.header} icon={Card.icon.tag} title={t("Margin calculator")} url="/margin-calculator" />
                                                            }
                                                        </div>
                                                        <div className="bg-gray-50 border border-gray-200 p-6 space-y-4">
                                                            {/* IMPERSONATE MENU */}
                                                            {
                                                                (can(["can_see_full_client_list"]) || roles(["DSM", "RSM", "ASM"])) &&
                                                                <div className="space-y-1">
                                                                    <h3 className="font-light">{t("Linea di vendita")}</h3>
                                                                    <Impersonate />
                                                                </div>
                                                            }
                                                            {/* language select */}
                                                            <div className="space-y-1">
                                                                <h3 className="font-light">{t("Lingua interfaccia")}</h3>
                                                                <Select
                                                                    styles={CustomStyles}
                                                                    options={AvailableLanguages}
                                                                    placeholder={t("scegli la lingua")}
                                                                    onChange={changeLanguage}
                                                                    escapeClearsValue
                                                                    menuPlacement={"top"}
                                                                    value={AvailableLanguages.filter(option => option.value === user?.interface_language_id)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                            <div >
                                                <hr />
                                                <Button
                                                    variant={Button.variant.text}
                                                    onClick={logout}
                                                >
                                                    {t("Logout")}
                                                    <MdKeyboardArrowRight />
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                        </div>
                        {openNav && <div className="fixed top-14 bottom-0 inset-x-0 bg-black bg-opacity-10" onClick={() => isMobile && setOpenNav(!openNav)} />}
                    </>
                ) : (
                    <div className={"px-6 w-full md:w-auto"}>
                        <Button className={"inline-block uppercase border text-center"} size={Button.size.full} variant={Button.variant.primary} href={process.env.REACT_APP_MICROSOFT_LOGIN_URL}>{t("Rothoblaas user")}</Button>
                    </div>
                )}
            </div>

            {/* ACCORDION INFO */}
            {
                !!user &&
                user?.customers?.length === 1 &&
                roles(["CLIENT", "GROUP_LEADER", "SUB_CLIENT"]) &&
                <div className="bg-gray-600 text-white overflow-hidden">
                    {/* INFO WHEN IS OPEN */}
                    {isOpen ? (

                        <div className="md:flex justify-between relative pr-8">
                            {/* ROTHO INFO */}
                            <div className="p-4 text-xs">
                                <div>{user?.customers?.[0].subsidiary?.name}</div>
                                <div className="font-light">{user?.customers?.[0].subsidiary?.street}</div>
                                <div className="font-light">
                                    {user?.customers?.[0].subsidiary?.city +
                                        " - " + user?.customers?.[0].subsidiary?.postalcode +
                                        " " + user?.customers?.[0].subsidiary?.region_id +
                                        ", " + user?.customers?.[0].subsidiary?.country_id}
                                </div>
                            </div>
                            {/* CUSTOMER INFO */}
                            <div className="p-4 text-xs">
                                <div>{t("Ciao")} <span className="capitalize">{user?.name}</span>,</div>
                                <div className="font-light">{t("benvenuto nella tua area personale")}</div>
                                <div className="font-light">{t("il tuo codice cliente è:")}</div>
                                <div>{user?.customers?.[0]?.code}</div>
                            </div>

                            <div className="flex flex-wrap">
                                {/* TECHNICAL SALES INFO */}
                                {
                                    !!user?.customers?.[0]?.technicalSale &&
                                    <div className="flex p-4 text-xs items-center gap-x-2">
                                        <img src={`https://resources.rothoblaas.com/eservice-images/people/${tsImageName}`} className="w-16 h-16 object-cover rounded-full" onError={({ currentTarget }) => {
                                            currentTarget.onerror = null;
                                            currentTarget.src = "/profle_not_found.webp";
                                        }} alt="Technical sales photo" />
                                        <div>
                                            <div>{t("Hai un dubbio commerciale?")}</div>
                                            <div className="uppercase">{user?.customers?.[0]?.technicalSale?.name} - <span className="text-gray-300">{t("TECNICO COMMERCIALE")}</span></div>
                                            {
                                                !!user?.customers?.[0]?.technicalSale?.phone &&
                                                <div className="flex items-center gap-x-1">
                                                    <AiFillPhone />
                                                    <span>{user?.customers?.[0]?.technicalSale?.phone}</span>
                                                </div>
                                            }
                                            {
                                                !!user?.customers?.[0]?.technicalSale?.email &&
                                                <div className="flex items-center gap-x-1">
                                                    <GrMail />
                                                    <span>{user?.customers?.[0]?.technicalSale?.email}</span>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                }
                                {/* CUSTOMER SERVICE INFO */}
                                {
                                    !!user?.customers?.[0]?.customerService &&
                                    <div className="flex p-4 text-xs items-center gap-x-2">
                                        <img src={`https://resources.rothoblaas.com/eservice-images/people/${csImageName}`} className="w-16 h-16 object-cover rounded-full" onError={({ currentTarget }) => {
                                            currentTarget.onerror = null;
                                            currentTarget.src = "/profle_not_found.webp";
                                        }} alt="Customer service photo" />
                                        <div>
                                            <div>{t("Hai un dubbio sul tuo ordine?")}</div>
                                            <div className="uppercase">{user?.customers?.[0]?.customerService?.name} - <span className="text-gray-300">{t("CUSTOMER SERVICE")}</span></div>
                                            {
                                                !!user?.customers?.[0]?.customerService?.phone &&
                                                <div className="flex items-center gap-x-1">
                                                    <AiFillPhone />
                                                    <span>{user?.customers?.[0]?.customerService?.phone}</span>
                                                </div>
                                            }
                                            {
                                                !!user?.customers?.[0]?.customerService?.email &&
                                                <div className="flex items-center gap-x-1">
                                                    <GrMail />
                                                    <span>{user?.customers?.[0]?.customerService?.email}</span>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>

                            <div className="absolute top-0 right-0 p-4 cursor-pointer" onClick={() => setIsOpen(false)}>
                                <IoMdCloseCircleOutline />
                            </div>
                        </div>
                    ) : (
                        <div className="flex justify-end px-4 p-4 cursor-pointer" onClick={() => setIsOpen(true)}>
                            <BiChevronDownCircle />
                        </div>
                    )}
                </div>
            }
        </div>
    );
};

export default Header;


